<template>
  <section class="gardenManagementPage">
    <div class="dis-flex flex-x-start flex-y-start">
      <div class="dis-flex flex-x-start flex-y-start">
        <el-date-picker
          v-model.trim="searchForm.qcDay"
          type="date"
          placeholder="选择筛选日期"
          value-format="yyyy-MM-dd"
          @change="selectTime"
        >
        </el-date-picker>
      </div>
    </div>

    <TableContainer title="">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        border
        :header-cell-style="tabHeader"
        :cell-style="{ textAlign: 'center' }"
      >
        <el-table-column prop="dayTime" label="日期" min-width="60" />
        <el-table-column prop="userId" label="id" min-width="60" />
        <el-table-column prop="organizationName" label="部门" min-width="60" />
        <el-table-column prop="fullName" label="姓名" min-width="60" />
        <el-table-column prop="mealsStatus" label="用餐核销状态" min-width="60" />
      </el-table>
    </TableContainer>
  </section>
</template>

<script>
import { queryNoUser } from "@api/bx/api_contentManagement";
import uploadPics from "@/components/Upload/uploadPics.vue";
import { parseTime } from "@utils/filters";
export default {
  components: {
    uploadPics,
  },
  data() {
    return {
      searchForm: {
        qcDay: parseTime(new Date(), "{y}-{m}-{d}"),
      },
      total: 0,
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 3600 * 1000 * 24;
        },
      },
    };
  },
  created() {
    this.queryNoUser();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    //列表时间筛选
    selectTime(val) {
      if (val !== null) {
        this.searchForm.qcDay = val;
        this.queryNoUser();
      } else {
        this.$alert("日期搜索条件不能为空", "提示", {
          dangerouslyUseHTMLString: true,
        });
     
      }
    },

    // 初始化列表
    queryNoUser() {
      queryNoUser(this.searchForm).then((res) => {
        this.tableData = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
  color: #fff;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
</style>
